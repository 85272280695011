import Theme from './theme'

export default {
  h1: `
    color: ${Theme.colors.black};
    font-size: ${Theme.typographyMobile[7]}em;
    line-height: 44px;
    font-family: ImpactURW;
    text-transform: uppercase;
    font-weight: normal;

    @media (min-width: ${Theme.breakpoints.l}) {
      font-size: ${Theme.typographyTablet[7]}em;
      line-height: 0.9em;
    }

    @media (min-width: ${Theme.breakpoints.xl}) {
      font-size: ${Theme.typography[7]}em;
      line-height: 72px;
    }
  `,

  h2: `
    color: ${Theme.colors.black};
    font-size: ${Theme.typographyMobile[6]}em;
    line-height: 40px;
    font-family: ImpactURW;
    text-transform: uppercase;
    font-weight: normal;

    @media (min-width: ${Theme.breakpoints.l}) {
      font-size: ${Theme.typographyTablet[6]}em;
      line-height: 1.18em;
    }

    @media (min-width: ${Theme.breakpoints.xl}) {
      font-size: ${Theme.typography[6]}em;
      line-height: 56px;
    }
  `,

  h3: `
    color: ${Theme.colors.black};
    font-size: ${Theme.typographyMobile[5]}em;
    line-height: 33px;
    font-family: ImpactURW;
    text-transform: uppercase;
    font-weight: normal;

    @media (min-width: ${Theme.breakpoints.l}) {
      font-size: ${Theme.typographyTablet[5]}em;
      line-height: 1.08em;
    }

    @media (min-width: ${Theme.breakpoints.xl}) {
      font-size: ${Theme.typography[5]}em;
      line-height: 40px;
    }
  `,

  h4: `
    color: ${Theme.colors.black};
    font-size: ${Theme.typographyMobile[4]}em;
    line-height: 28px;
    font-family: ImpactURW;
    font-weight: normal;

    @media (min-width: ${Theme.breakpoints.l}) {
      font-size: ${Theme.typographyTablet[4]}em;
      line-height: 1.1em;
    }

    @media (min-width: ${Theme.breakpoints.xl}) {
      font-size: ${Theme.typography[4]}em;
      line-height: 36px;
    }
  `,

  h5: `
    color: ${Theme.colors.black};
    font-size: ${Theme.typographyMobile[3]}em;
    line-height: 22px;
    font-family: ImpactURW;
    text-transform: uppercase;
    font-weight: normal;

    @media (min-width: ${Theme.breakpoints.l}) {
      font-size: ${Theme.typographyTablet[3]}em;
      line-height: 1.09em;
    }

    @media (min-width: ${Theme.breakpoints.xl}) {
      font-size: ${Theme.typography[3]}em;
      line-height: 26px;
    }
  `,

  h6: `
    color: ${Theme.colors.black};
    font-size: ${Theme.typographyMobile[2]}em;
    line-height: 22px;
    font-family: ImpactURW;
    text-transform: uppercase;
    font-weight: normal;

    @media (min-width: ${Theme.breakpoints.l}) {
      font-size: ${Theme.typographyTablet[2]}em;
      line-height: 1.08em;
    }

    @media (min-width: ${Theme.breakpoints.xl}) {
      font-size: ${Theme.typography[2]}em;
      line-height: 26px;
    }
  `,

  headerMedium: `
    font-family: AdelleRegular;
    color: ${Theme.colors.black};
    font-size: 24px;
    line-height: 34px;
    font-weight: normal;

    @media (min-width: ${Theme.breakpoints.l}) {
      font-size: 26px;
      line-height: 36px;
    }
  `,

  headerSmall: `
    font-family: AdelleBold;
    font-size: 16px;
    line-height: 24px;
    font-weight: normal;

    @media (min-width: ${Theme.breakpoints.l}) {
      font-size: 20px;
      line-height: 32px;
    }
  `,

  subline: `
    font-family: AdelleSansLight;
    font-size: 18px;
    line-height: 28px;
    font-weight: normal;

    @media (min-width: ${Theme.breakpoints.l}) {
      font-size: 22px;
      line-height: 32px;
    }
  `,

  caption: `
    color: ${Theme.colors.white};
    font-size: 12px;
    line-height: 18px;
    font-family: AdelleSansRegular;

    @media (min-width: ${Theme.breakpoints.l}) {
      font-size: 16px;
      line-height: 26px;
    }
  `,

  bodyLarge: `
    font-family: AdelleSansLight;
    color: ${Theme.colors.greyDarkest};
    font-size: 20px;
    line-height: 32px;
    font-weight: normal;

    @media (min-width: ${Theme.breakpoints.l}) {
      font-size: 21px;
      line-height: 35px;
    }
  `,

  body: `
    font-family: AdelleSansLight;
    color: ${Theme.colors.greyDarkest};
    font-size: 16px;
    line-height: 28px;
    font-weight: normal;

    @media (min-width: ${Theme.breakpoints.l}) {
      font-size: 18px;
      line-height: 32px;
    }
  `,

  bodySmall: `
    color: ${Theme.colors.black};
    font-size: ${Theme.typographyMobile[1]}em;
    line-height: 28px;

     @media (min-width: ${Theme.breakpoints.l}) {
      font-size: ${Theme.typography[1]}em;
    }
  `,

  footnote: `
    font-family: AdelleSansRegular;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
  `,

  label: `
    color: ${Theme.colors.white};
    font-size: 14px;
    line-height: 25px;
    letter-spacing: .3px;
  `,

  srOnly: `
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    position: absolute;
    width: 1px;
  `,
}
